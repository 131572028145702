import '@popperjs/core/';
import './App.scss';
import Home from './pages/Home';

function App() {
  return (
    <Home />
  );
}

export default App;
